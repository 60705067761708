<template>
  <div class="customer-profiling-list-table-cell">
    <span class="customer-profiling-list-table-cell__text">
      {{ displayValue }}
    </span>
  </div>
</template>

<script>
import { get } from 'lodash';
import { computed } from 'vue';
import { format, zonedTimeToUtc } from 'date-fns-tz';

export default {
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    customerProfile: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const displayValue = computed(() => {
      const value = get(props.customerProfile, props.field);
      if (!value) return '-';
      return format(zonedTimeToUtc(value, 'UTC'), 'MMM d, yyyy');
    });

    return {
      displayValue,
    };
  },
};
</script>
