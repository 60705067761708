<template>
  <div class="customer-profiling-list-table-cell customer-profiling-list-table-cell--is-customer-id-cell">
    <span class="customer-profiling-list-table-cell__text">
      {{ displayValue }}
    </span>
    <Icon
      class="customer-profiling-list-table-cell--is-customer-id-cell__icon"
      :name="isActive ? 'check' : 'copy'"
      @click.stop="copyCustomerId"
    />
  </div>
</template>

<script>
import { get, isNil } from 'lodash';
import { ref, computed } from 'vue';
import { copyToClipboard } from '@/services/helpers/mappings-mapper';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    customerProfile: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isActive = ref(false);

    const displayValue = computed(() => {
      const value = get(props.customerProfile, props.field);
      return isNil(value) ? '-' : value;
    });

    const copyCustomerId = () => {
      copyToClipboard(displayValue.value);
      if (!isActive.value) {
        let timeoutId = setTimeout(() => {
          isActive.value = false;
          clearTimeout(timeoutId);
          timeoutId = null;
        }, 1000);
      }
      isActive.value = true;
    };

    return {
      displayValue,
      isActive,
      copyCustomerId,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-list-table-cell {
  &--is-customer-id-cell {
    display: flex;
    align-items: center;
    gap: 4px;
    overflow: hidden;
    width: auto;

    &__icon {
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      padding: 2px;
      stroke: #000;
      cursor: pointer;
    }
  }
}
</style>
