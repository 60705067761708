<template>
  <div class="customer-profiling-list-header-operator-filter">
    <TabGroup>
      <Tab
        v-for="option in options"
        :key="option.id"
        :is-active="isTabActive(option)"
        @click="selectTab(option.id)"
      >
        {{ option.label }}
      </Tab>
    </TabGroup>
  </div>
</template>

<script>
import TabGroup from '@/components/common/TabGroup';
import Tab from '@/components/common/Tab';

export default {
  components: {
    TabGroup,
    Tab,
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: {
    'update:modelValue': {
      type: String,
    },
  },
  setup(props, { emit }) {
    const isTabActive = (option) => props.modelValue === option.id;
    const selectTab = (option) => {
      emit('update:modelValue', option);
    };

    return {
      isTabActive,
      selectTab,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-list-header-operator-filter {
  height: 100%;

  .tab-group,
  .tab {
    height: 100%;
  }

  .tab {
    max-height: initial;
  }
}
</style>
