<template>
  <div class="customer-profiling-list-table-cell customer-profiling-list-table-cell--is-client">
    <Icon
      v-if="iconName"
      :name="iconName"
    />
    <span class="customer-profiling-list-table-cell__text">
      {{ displayValue }}
    </span>
  </div>
</template>

<script>
import { get, isNil } from 'lodash';
import { computed } from 'vue';
import { getIconByOperator } from '@/services/helpers/super-admin';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    customerProfile: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const displayValue = computed(() => {
      const value = get(props.customerProfile, props.field);
      return isNil(value) ? '-' : value;
    });
    const iconName = computed(() => getIconByOperator(displayValue.value));

    return {
      displayValue,
      iconName,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-list-table-cell {
  &--is-client {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
</style>
