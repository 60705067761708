<template>
  <CustomerProfilingFilters
    :model-value="filters"
    :options="options"
    @update:model-value="saveChanges"
    @cancel="cancelChanges"
  />
</template>

<script>
import { cloneDeep, filter as filterArray } from 'lodash';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { serializeFilterQuery } from '@/services/helpers/filters';
import { getCustomerListFilters } from '@/services/helpers/customer-profiling';
import CustomerProfilingFilters from '../common/CustomerProfilingFilters';

export default {
  components: {
    CustomerProfilingFilters,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const filters = computed(() => (filterArray(store.getters['betTicker/allCustomersFilters'], (filter) => !filter.meta.hideSidebar)));
    const options = computed(() => filterArray(getCustomerListFilters(), (filter) => !filter.meta.hideSidebar));

    const saveChanges = (newFilters) => {
      const { page, ...query } = cloneDeep(route.query);
      if (newFilters.length) {
        query.filter = serializeFilterQuery(newFilters, {
          oddsFormat: store.getters.selectedOddFormat?.id || '',
        });
      } else {
        delete query.filter;
      }
      router.replace({
        ...route,
        query,
      });
      store.dispatch('betTicker/setAllCustomersSidebar', '');
    };
    const cancelChanges = () => {
      store.dispatch('betTicker/setAllCustomersSidebar', '');
    };

    return {
      filters,
      options,
      saveChanges,
      cancelChanges,
    };
  },
};
</script>
