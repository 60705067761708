<template>
  <div
    class="customer-profiling-list-header-flag-filter"
    @click="toggleModelValue"
  >
    <Toggle
      :model-value="modelValue"
      @update:model-value="updateModelValue"
    />
    Show only flagged customers
  </div>
</template>

<script>
import Toggle from '@/components/common/Toggle';

export default {
  components: {
    Toggle,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:modelValue': {
    },
  },
  setup(props, { emit }) {
    const updateModelValue = (newModelValue) => {
      emit('update:modelValue', newModelValue);
    };
    const toggleModelValue = () => {
      updateModelValue(!props.modelValue);
    };

    return {
      updateModelValue,
      toggleModelValue,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-list-header-flag-filter {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  .toggle {
    flex-shrink: 0;
  }
}
</style>
