<template>
  <div class="customer-profiling-list-table-cell customer-profiling-list-table-cell--is-strength-cell">
    <div
      ref="rootSelectElement"
      class="customer-profiling-list-table-cell__select"
    >
      <div
        ref="rootFieldElement"
        :class="[
          'customer-profiling-list-table-cell__select-field',
          { 'customer-profiling-list-table-cell__select-field--is-active': selectedLabel },
        ]"
        @click.stop="toggleMenu"
      >
        <span
          :class="[
            'customer-profiling-list-table-cell__select-field-label',
            { 'customer-profiling-list-table-cell__select-field-label--is-placeholder': !selectedLabel }
          ]"
        >
          {{ selectedLabel }}
        </span>
        <Icon
          class="customer-profiling-list-table-cell__select-field-icon"
          name="chevron-selector"
        />
      </div>
      <div
        v-if="isMenuOpen"
        ref="rootMenuElement"
        class="customer-profiling-list-table-cell__select-menu"
      >
        <div
          v-for="option in strengthOptions"
          :key="option.id"
          class="customer-profiling-list-table-cell__select-item"
          @click.stop="setSelectedStrengthId(option.id)"
        >
          <div class="customer-profiling-list-table-cell__select-item-label">
            <span class="customer-profiling-list-table-cell__select-item-label-id">
              {{ option.id }}
            </span>
            <span class="customer-profiling-list-table-cell__select-item-label-text">
              &minus; {{ option.label }}
            </span>
          </div>
          <Icon
            v-if="selectedStrengthId === option.id"
            class="customer-profiling-list-table-cell__select-item-icon"
            name="check"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, find } from 'lodash';
import {
  ref,
  computed,
  inject,
  onUpdated,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import { onClickOutside } from '@vueuse/core';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    customerProfile: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const isTableScrolling = inject('isTableScrolling', false);

    const rootSelectElement = ref(null);
    const rootFieldElement = ref(null);
    const rootMenuElement = ref(null);

    const isMenuOpen = ref(false);
    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    const selectedStrengthId = computed(() => get(props.customerProfile, props.field) || 0);
    const strengthOptions = ref([
      {
        id: 1,
        label: 'Confirmed recreational',
      },
      {
        id: 2,
        label: 'Suspected recreational',
      },
      {
        id: 3,
        label: 'Neutral',
      },
      {
        id: 4,
        label: 'Suspected sharp',
      },
      {
        id: 5,
        label: 'Confirmed sharp',
      },
    ]);

    const selectedLabel = computed(() => {
      const id = Number(selectedStrengthId.value);
      const option = find(strengthOptions.value, { id });
      return Number(option?.id || '0');
    });

    const setSelectedStrengthId = (newSelectedStrengthId) => {
      store.dispatch('betTicker/applyCustomerProfileStrength', {
        accountId: props.customerProfile.id,
        strength: newSelectedStrengthId,
      });
      isMenuOpen.value = false;
    };

    onUpdated(() => {
      const fieldBounds = rootFieldElement.value?.getBoundingClientRect?.();
      const menuBounds = rootMenuElement.value?.getBoundingClientRect?.();

      if (!fieldBounds || !menuBounds) return;

      if (menuBounds.bottom > window.innerHeight) {
        rootMenuElement.value.style.top = `calc(100% - ${fieldBounds.height}px - ${menuBounds.height}px - 8px`;
      } else {
        rootMenuElement.value.style.top = '100%';
      }
    });

    onClickOutside(rootSelectElement, () => {
      isMenuOpen.value = false;
    });

    watch(isTableScrolling, (newIsTableScrolling) => {
      if (newIsTableScrolling && isMenuOpen.value) isMenuOpen.value = false;
    });

    return {
      rootSelectElement,
      rootFieldElement,
      rootMenuElement,
      isMenuOpen,
      toggleMenu,
      selectedStrengthId,
      strengthOptions,
      selectedLabel,
      setSelectedStrengthId,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-list-table-cell {
  &--is-strength-cell {
    overflow: visible !important;
  }

  &__select {
    position: relative;

    &-field {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      padding: 0 8px;
      cursor: pointer;
      color: #191414;
      border: 1px solid #f0f0f0;
      height: 32px;
      border-radius: 4px;
      overflow: hidden;
      min-height: 32px;
      max-height: 32px;
      width: 65px;
      max-width: 65px;
      min-width: 65px;

      &:hover {
        background-color: #FAFAFA;
        border-color: #DDDDDD;
      }

      &--is-active {
        border-color: #DDDDDD;
      }

      &-label {
        width: 30px;
        min-width: 30px;
        max-width: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #191414;

        &--is-placeholder {
          color: #CDCDCD;
        }
      }

      &-icon {
        flex-shrink: 0;
      }
    }

    &-menu {
      position: absolute;
      top: 100%;
      left: 0;
      min-width: 100%;
      max-height: 230px;
      overflow-y: auto;
      z-index: 9000;
      display: flex;
      flex-direction: column;
      border-radius: var(--radius-medium, 4px);
      border: 1px solid var(--neutral-stroke-default-resting, #f0f0f0);
      background: var(--neutral-bg-default-resting, #fff);
      box-shadow: 0 2px 4px 0 rgba(25, 20, 20, .08);
      margin-top: 4px;
      padding: 4px 0;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      cursor: pointer;
      font-family: Rubik, sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      height: 32px;
      padding: 8px;

      &:hover {
        background-color: #fafafa;
        outline: none;
      }

      &-label {
        display: flex;
        align-items: center;
        gap: 4px;
        width: 150px;

        &-id {
          color: #191414;
        }

        &-text {
          color: #6D6D6D;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &-icon {
        stroke: #191414;
      }
    }
  }
}
</style>
