<template>
  <div class="customer-profiling-list-header-search-filter">
    <TextInput
      :model-value="modelValue"
      @update:model-value="updateModelValue"
      placeholder="Search customer ID or alias"
      icon="search"
      small
    />
  </div>
</template>

<script>
import { debounce } from 'lodash';
import TextInput from '@/components/common/TextInput';

export default {
  components: {
    TextInput,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: {
    'update:modelValue': {
      type: String,
    },
  },
  setup(props, { emit }) {
    const updateModelValue = debounce((newModelValue) => {
      emit('update:modelValue', newModelValue);
    }, 350);

    return {
      updateModelValue,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-list-header-search-filter {
  width: 250px;

  .text-input {
    width: 100%;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    .button {
      height: 32px;
      min-height: 32px;
      max-height: 32px;
    }
  }
}
</style>
