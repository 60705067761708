<template>
  <div class="customer-profiling-list-table-cell">
    <span class="customer-profiling-list-table-cell__text">
      {{ displayValue }}
    </span>
  </div>
</template>

<script>
import { get, isNil } from 'lodash';
import { computed } from 'vue';

export default {
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    customerProfile: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const displayValue = computed(() => {
      const value = get(props.customerProfile, props.field);
      return isNil(value) ? '-' : value;
    });

    return {
      displayValue,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-list-table-cell {
  background: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}
</style>
